<script setup>
    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    }

    function createMockUrl() {
        document.getElementById("generatedurl").value = 'https://mock.teapotapimock.com/' + uuidv4();
    }
</script>

<template>
    <div class="section">
        <p class="title is-4">Create a mock domain</p>
        <p>Here you can generate a unique url that can be used as the domain for testing.</p>
        <button @click="createMockUrl()" class="button is-link">Generate</button>
        <input id="generatedurl" class="input is-rounded" type="text" value="Click to generate url" readonly style="max-width:600px; margin-left:10px;">
    </div>
</template>