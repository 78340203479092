<template>
    <MainDocs />
    <GenerateUrl />
</template>

<script>
    import MainDocs from './components/MainDocs.vue'
    import GenerateUrl from './components/GenerateUrl.vue'

    export default {
      name: 'App',
      components: {
          MainDocs,
          GenerateUrl
      }
    }
    </script>

    <style>
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #2c3e50;
    }
</style>
