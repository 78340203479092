<template>
    <main>
        <section class="hero is-small is-success">
            <div class="hero-body">
                <p class="title">
                    Welcome to Teapot Api Mock <img src="../assets/tt-logo.png" />
                </p>
                <p class="subtitle">
                    You're a teapot! (418)
                    <a href="https://twitter.com/intent/tweet?screen_name=ginoarpesella" target="_blank" style="margin-left: 10px;">
                        <span class="icon" title="tweet @ me">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="#26a7de">
                                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                            </svg>
                        </span>
                    </a>
                    <a href="https://www.syntacticsalt.net/" target="_blank" style="margin-left: 10px;">
                        <span class="icon" title="See my other pages">
                            <img src="./../assets/SyntacticSaltIcon.png" style="height: 22px;">
                        </span>
                    </a>
                    <a class="bmc-button" target="_blank" href="https://www.buymeacoffee.com/ginoarpesella">
                        <link href="https://fonts.googleapis.com/css?family=Cookie" rel="stylesheet" />
                        <img src="../assets/bmc.svg" alt="Buy me a coffee" />
                        <span style="margin-left:5px">Buy me a coffee</span>
                    </a>
                </p>
            </div>
        </section>
        <section class="section">
            <div class="content is-medium">
                <p>
                    The purpose of this project is to solve a problem we all face when running end-to-end tests.
                    The dependencies on outside apis to deliver. This project aims to solve this problem by acting as a mocking tool for apis.
                    The idea is to have a simple to use tool that can be used to mock apis and return the desired response.
                    <br />
                    <br />
                    With options like:
                    <ul>
                        <li>Setting the response body of a POST call</li>
                        <li>Responding with the headers you sent</li>
                        <li>Or even setting the http status code you want back</li>
                    </ul>

                    To keep the api simple to use all you need to do is call into the api with the correct headers.

                    <table class="table">
                        <thead>
                            <tr>
                                <th>Header name</th>
                                <th>Value</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><code>Action</code></td>
                                <td><code>set</code></td>
                                <td>Tells the api to use your request as the response when called without this header.</td>
                            </tr>
                            <tr>
                                <td><code>Action</code></td>
                                <td><code>delete</code></td>
                                <td>Deletes the previously set request.</td>
                            </tr>
                            <tr>
                                <td><code>Action_HttpResponseCode</code></td>
                                <td>Examples: <code>503</code> / <code>201</code> / <code>404</code></td>
                                <td><strong>'Action' header must be present.</strong> Sets the http status code. It can be left empty with a default value set of <code>200</code></td>
                            </tr>
                        </tbody>
                    </table>
                </p>
                <div class="notification">If you have not set the response before requesting, you will receive <code>You're a teapot!</code> with an http status code of <code>418</code>.</div>
                <div class="notification is-warning is-light">Messages set will have a time to live of 2 minutes <code>120 seconds</code>. After that your set request will be reset. Keep this in mind if you have long running requests.</div>

                <div class="columns">
                    <div class="column">
                        <p class="title is-3">So how does this work?</p>
                        <p class="title is-5">'set' your request</p>
                        <p>
                            Send a request to the mock api. The mock api will record the headers, stats code (<code>200</code> by default), request body, http method and the full url path.
                            The mock api will hold this information in memory and send it back when requested. Ensure the full url path, including all query string parameters and their values,
                            are the same when requesting.
                            The mock api uses the http method and full path as a way to identify the request. So make sure both match when requesting to get your 'set' response.
                            The response of '<code>Mock endpoint set</code>' and http status code <code>200</code> means the <code>set</code> request was completed successfully.
                        </p>
                    </div>
                    <div class="column">
                        <div class="box">
                            <div class="title is-4">'set' example</div>
                            <pre class="precode" style="max-height:350px;">
                            <code>
curl --location POST 'https://mock.teapotapimock.com/resource1/value/bla?p=2&next=one' \
--header 'Action: set' \
--header 'Action_HttpResponseCode: 424' \
--header 'RandomHeaderIWantReturned: RandomValue' \
--header 'Content-Type: application/json' \
--data '{
    "somebody": "out there?",
    "yes": 1
}'
                                    </code>
                                </pre>
                            <br />
                            Response: <code>Mock endpoint set</code> with http status code <code>200</code>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <p class="title is-5">Get your response</p>
                        <p>
                            Make the same call again, but this time <strong>without</strong> the 'set' header (<code>Action: set</code>).
                            The mock api will look for the request you made and return the response you set.
                            The mock api will also return the headers you sent in the request and the http status code, if it was set.
                            The original headers <code>Action</code> and <code>Action_HttpResponseCode</code> will be removed from the response.
                            The body of your call will be ignored along with any additional headers you sent.
                        </p>
                    </div>
                    <div class="column">
                        <div class="box">
                            <div class="title is-4">'get' example</div>
                            <pre class="precode" style="max-height:230px;">
                            <code class="language-html">
curl --location 'https://mock.teapotapimock.com/resource1/value/bla?p=2&next=one' \
--header 'Content-Type: text/plain' \
--data 'The body will not be checked. Just the url and http method.'
                                    </code>
                                </pre>
                            <br />
                            Response: <code>424</code> status code with <code>json</code> body and headers you sent.
                            <br />
                            <br />
                            <pre class="precode" style="max-height:210px;">
                            <code class="language-json">
{
    "somebody": "out there?",
    "yes": 1
}
                                    </code>
                                </pre>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="title is-3">
                            Keep it clean!
                        </div>
                        <div class="notification is-danger  is-light">
                            We will not be responsible for any leakage of sensitive information. The information used should not be production information and settings.
                            This service should only be used for testing and development purposes. Anything outside of this rang is not recommended.
                        </div>
                        <p>
                            When using the mock api there are a few things to try and avoid sending. From a security standpoint you should avoid sending any sensitive information.
                            The mock api is not designed to be used in production. It is designed to be used in development and testing environments.
                        </p>
                        <div class="title is-6">
                            Passwords / Secrets / Tokens
                        </div>
                        <p>
                            Try and avoid sending any passwords, secrets or tokens. If you need them for testing, make sure the tokens are short lived and not used in production.
                            If you can change the password or secret so it is no longer valid after testing, that would be best. If sensitive information is sent, you should consider using the
                            <code>Action: delete</code> to remove the sensitive data as a final step.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<style scoped>
    .bmc-button > span {
        font-family: 'Cookie', cursive !important;
    }

    .bmc-button img {
        width: 27px !important;
        margin-bottom: 1px !important;
        box-shadow: none !important;
        order: none !important;
        vertical-align: middle !important;
    }

    .bmc-button {
        line-height: 36px !important;
        height: 37px !important;
        text-decoration: none !important;
        display: inline-flex !important;
        color: #333333 !important;
        background-color: #FFDD00 !important;
        border-radius: 3px !important;
        border: 1px solid transparent !important;
        padding: 1px 9px !important;
        font-size: 23px !important;
        letter-spacing: 0.6px !important;
        box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
        -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
        margin: 0 auto !important;
        -webkit-box-sizing: border-box !important;
        box-sizing: border-box !important;
        -o-transition: 0.3s all linear !important;
        -webkit-transition: 0.3s all linear !important;
        -moz-transition: 0.3s all linear !important;
        -ms-transition: 0.3s all linear !important;
        transition: 0.3s all linear !important;
    }

        .bmc-button:hover, .bmc-button:active, .bmc-button:focus {
            -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
            text-decoration: none !important;
            box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
            opacity: 0.85 !important;
            color: #555555 !important;
        }
</style>